<template>
  <v-main class="auth-pages">
    <div class="header-auth position-relative border-radius-xl min-vh-100">
      <v-container class="py-0">
        <v-row>
          <v-col lg="4" md="7" class="d-flex flex-column mx-lg-0 mx-auto">
            <fade-transition :duration="200" origin="center top" mode="out-in">
              <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
                <div class="card-padding pb-4">
                  <!--                  <div class="text-center">-->
                  <!--                    <p class="text-sm text-body mt-3 mb-0">-->
                  <!--                      Go back to-->
                  <!--                      <router-link-->
                  <!--                          :to="{name:'Landing'}"-->
                  <!--                          class="text-default text-gradient-default text-decoration-none-->
                  <!--              font-weight-bold"-->
                  <!--                      >-->
                  <!--                        homepage-->
                  <!--                      </router-link>-->

                  <!--                    </p>-->
                  <!--                  </div>-->
                </div>
              </v-card>
            </fade-transition>
          </v-col>
          <v-col
            cols="6"
            class="
              d-lg-flex d-none
              h-100
              my-auto
              pe-0
              pt-0
              position-absolute
              top-0
              end-0
              text-center
              justify-center
              flex-column
            "
          >
            <div
              class="
                position-relative
                bg-gradient-default
                h-100
                ma-4
                px-16
                border-radius-lg
                d-flex
                flex-column
                justify-center
              "
            >
              <v-img
                src="@/assets/img/shapes/pattern-lines.svg"
                alt="pattern-lines"
                class="position-absolute opacity-4 start-0 h-100 w-100"
              >
              </v-img>
              <h4 class="text-h4 mt-10 text-white font-weight-bolder mb-2">
                404
              </h4>
              <p class="text-white">
                {{ 'The page you are looking for does not exist!' | trans }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-main>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  name: "not-found",
  components: { FadeTransition },
  data() {
    return {};
  },
};
</script>
